<template>
  <div class="mb-5">
    <b-card class="m-3">
      <h5>Download THe template pack and read the instructions file</h5>
      <a href>Download Template Pack</a>
    </b-card>

    <b-card class="m-3">
         <template class="one" v-slot:header>
        <b-card-text class="text">Upload File</b-card-text>
      </template>
      <div class="row d-flex justify-content-end">
          <div class="col-8 ">
      <w-uploader class="mt-1" :dragAndDrop="dragAndDropText" :browse="'Browse'" :acceptedMediaType="'Icon'" @rawFiles="getFilesUploaded($event)" :extraDetails="'(supported formats jpg, png, jpeg, gif file upto 2MB )'" :lblClass="'lbl-font-size'" :extraDetailClass="'extra-lbl-font-size'"/>
      </div>
      <div class=" upload col-4 ">
          <b-button variant="primary">Upload File</b-button>
      </div>
      </div>
      
    </b-card>

    <b-card class="m-3">
      <strong>Files Uploaded Previously</strong>
      <w-table :tableData="tabledata" @downloadCSEvent="downloaded($event)"></w-table>
    </b-card>

    <div class="row mt-4 d-flex justify-content-end">
     
        <b-button type="submit" class="save mr-4" variant="primary" size="sm" @click="goback($event)">Back</b-button>
      
    </div>
  </div>
</template>

<script>
import ReportsTable from "../../widgets/ReportsTable.vue";
import FileUploader from "../../widgets/FileUploader.vue";
export default {
  components: {
    "w-uploader":FileUploader,
    "w-table": ReportsTable
  },
  data() {
    return {
        dragAndDropText: "<img src='../assets/cloud-computing.svg'/>",
      tabledata: {
        fields: [
          {
            key: "uploadId",
            label: "Upload Id"
          },
          {
            key: "uploadedBy",
            label: "Uploaded By"
          },
          {
            key: "uploadDate",
            label: "Upload Date & time"
          },
          {
            key: "fileName",
            label: "File Name"
          },
          {
            key: "status",
            label: "Status"
          },
          {
            key: "details",
            label: "Details"
          },
          {
            key: "file_field",
            label: "File"
          }
        ],

        items: [
          {
            uploadId: "1432133",
            uploadedBy: "Suma",
            uploadDate: "12th-dec-2020",
            fileName: "Batch_2.csv",
            status: "Processed",
            details: "Updated stocks of 139 products"
          },
          {
            uploadId: "1432133",
            uploadedBy: "Suma",
            uploadDate: "12th-dec-2020",
            fileName: "Batch_2.csv",
            status: "Processed",
            details: "Updated stocks of 139 products"
          }
        ]
      }
    };
  },
  methods:{
      goback(event){
          this.$emit("goback")
      }
  }
};
</script>


<style scoped>
a {
  text-decoration: underline;
}

.save {
  width: 180px;
 
  height: 33px;
  border-radius: 5px;
  border: solid 1px #5fa6e7;
  
  margin-right: 30px;
  padding: 3px;
}
.upload{
    margin-top: 200px;
    border-radius: 2px;
     width: 149px;
}
</style>