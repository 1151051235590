<template>
  <div>
    <div class="upload-border">
      <div class="label-style">
        <div for="file" :class="lblClass" class="drag-drop" style="margin-top: 35px;">
          <img v-if="dragAndDrop !=''" class="images" src='../assets/cloud-computing.svg'/>
          <p for="file" :class="lblClass" class="drag-drop-details pt-2">{{ browse }}</p>
          <p class="or_label">{{or}}</p>
          <button v-show="browseButton" class="browse_btn">{{browseButton}}</button>
        </div>
        <div v-if="extraDetails" for="file" :class="extraDetailClass" class="w-100">{{ extraDetails }}</div>
      </div>
      <input
        type="file"
        class="input-style"
        multiple="true"
        id="files"
        ref="files"
        v-on:change="handleFileUpload()"
        :accept="getAcceptType()"
        @dragover.prevent
      >
    </div>
    <div v-for="(file, i) in rawFiles" :key="i">
      <div class="row m-0 py-0 px-1 w-100">
        <div class="col-7 p-0" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
          <span class="float-left" style="margin-bottom:10px; opacity: 0.7;">{{ file.name }}</span>
        </div>
        <div class="col-4 p-0">
          <p class="float-left text-center" style="margin-bottom:10px; opacity: 0.7;">{{ getSize(file.size) }}</p>
        </div>
        <div class="col-1 p-0" @click="removeFile(i)">
          <img class="float-right cursor-pointer" width="18px" height="auto" style="opacity: 0.7" src="@/assets/CloseModel.svg">
        </div>
      </div>
    </div>
    <p class="alert alert-danger py-1 text-center" v-if="duplicateFile">File Already Uploaded</p>
    <p class="alert alert-danger py-1 text-center" v-if="errorUploading">Upload valid file type</p>
  </div>
</template>
<script>
import { EventBus } from "@/main.js";

export default {
  props: ["dragAndDrop", "browse","or", "browseButton", "acceptedMediaType","existMediaName", "extraDetails", "lblClass", "extraDetailClass"],
  data() {
    return {
      file: "",
      files: [],
      rawFiles: [],
      fileName: "",
      fileNameArray: [],
      errorUploading: false,
      duplicateFile: false,
      mediaExtensionArray: []
    };
  },
  created() {
    this.mediaExtensionArray = this.setMediaExtensionArray();
    EventBus.$on("changedMediaType", (mediaType) => {
      console.log(mediaType)
      this.acceptedMediaType = mediaType.selectedAssetType;
      this.mediaExtensionArray = this.setMediaExtensionArray();
      this.existMediaName = mediaType.existFileName?mediaType.existFileName:[];
      this.rawFiles = [];
    })
  },
  methods: {
    setMediaExtensionArray() {
      console.log(this.acceptedMediaType)
      switch(this.acceptedMediaType) {
        case "Video": return ["mp4"];
        case "Audio": return ["mp3"];
        case "Image": return ["jpeg","png", "jpg","gif"];
        case "Icon": return ['png','jpg','jpeg','gif']
        case "all": return ["mp4", "mov", "mp3", "jpeg", "gif", "jpg", "png", "docx", "pdf"];
        case "Text": return ["txt","pdf","html"];
      }
    },
    getAcceptType(){
      switch(this.acceptedMediaType){
        case "Video": return ".mp4";
        case "Audio": return ".mp3"
        case "Image": return ".jpeg, .jpg, .gif, .png"
        case "Icon": return ".png, .jpg, .jpeg, .gif"
        case "all": return ".mp4, .mov, .mp3, .jpeg, .gif, .jpg, .png, .docx, .pdf"
        case "Text": return ".txt, .pdf, .html"
      }
    },
    handleFileUpload(event) {
      console.log(this.$refs.files.files)
      this.files = this.$refs.files.files;
      this.existMediaName = this.existMediaName?this.existMediaName:[];
      for (var i = 0; i < this.$refs.files.files.length; i++) {
        (file => {
          let extention = file.name.split(".")[file.name.split(".").length - 1];
            console.log(this.existMediaName, file.name.split(" ").join("_") ," existed media name")
          if (this.mediaExtensionArray.includes(extention.toLowerCase()) ) {
            if(this.rawFiles.indexOf(file) == -1 && this.fileNameArray.indexOf(file.name) == -1 && this.existMediaName.indexOf(file.name.split(" ").join("_")) == -1) {
              this.errorUploading = false;
              this.fileNameArray.push(file.name);
              this.rawFiles.push(file);
            } else {
              this.duplicateFile = true;
              setTimeout(() => {
                this.duplicateFile = false;
              }, 3000);
            }
          } else {
            this.errorUploading = true;
            setTimeout(() => {
              this.errorUploading = false;
            }, 3000);
          }
        })(this.files[i]);
      }
      this.$emit("rawFiles", this.rawFiles);
    },
    removeFile(index) {
      this.fileNameArray.splice(index, 1);
      this.rawFiles.splice(index, 1);
      this.$emit("rawFiles", this.rawFiles);
    },
    getSize(size) {
        if(size < 1000000){
            var _size = Math.floor(size/1000) + 'KB';
            return (_size);
        }
        else{
            var _size = Math.floor(size/1000000) + 'MB';  
            return (_size);
        }
    }
  }
};
</script>
<style scoped>
.browse_btn{
  font-family: ProximaNova;
  font-size: 17px;
  color: #2e8cd3;
  border-radius: 2px;
  border: solid 1px #2e8cd3;
  padding: 3px 20px;
}
.label-style {
  position: absolute;
  left: 0;
  right: 0;
  color: #1285ff;
  font-size: 16px;
  width: 100%;
  margin: auto;
  height: inherit;
}
.upload-border {
  border: 2px dashed #2e8cd3;
  text-align: center;
  border-radius: 6px;
  box-sizing: border-box;
  margin-bottom: 20px;
  height: 18em;
}
.input-style {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
}
.drag-drop {
  color: #000;
  text-align: center;
  margin-bottom: 0;
}
.drag-drop-details, .or_label {
  color: #1b1c1d;
  /* margin-bottom: 0; */
  text-align: center;
  font-family: ProximaNova;
  font-size: 17px;
}
.images{
    height: 90px;
    width:100px;
}
</style>